/* Contenedor principal */
.letter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(135deg, #fde2e4 0%, #fcefe9 100%);
    min-height: 100vh;
    box-sizing: border-box;
}

/* Encabezado */
.letter-header h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #e63946;
    margin-bottom: 10px;
    text-shadow: 1px 1px 2px #f1a1b8;
    text-align: center;
}

.letter-header .intro-text {
    font-size: 1.2rem;
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

/* Mensaje */
.letter-message {
    margin: 20px 0;
    padding: 20px;
    font-size: 1.1rem;
    line-height: 1.8;
    text-align: center;
    color: #333;
    font-style: italic;
    border-left: 4px solid #e63946;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Texto resaltado */
.letter-message .highlight {
    color: #e63946;
    font-weight: bold;
}

/* Botón */
.open-pdf-btn {
    padding: 12px 24px;
    background-color: #e63946;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.open-pdf-btn:hover {
    background-color: #d62828;
    transform: scale(1.05);
}

/* Pie de página */
.letter-footer {
    font-size: 1rem;
    color: #777;
    text-align: center;
    padding: 10px;
}

/* Diseño responsivo */
@media (max-width: 768px) {
    .letter-header h1 {
        font-size: 2rem;
    }

    .letter-header .intro-text {
        font-size: 1rem;
    }

    .letter-message {
        font-size: 1rem;
        padding: 15px;
    }

    .open-pdf-btn {
        font-size: 0.9rem;
        padding: 10px 20px;
    }

    .letter-footer p {
        font-size: 0.9rem;
    }
}